<template>
    <div class="discounts" id="disc">
        <div class="_container">
            <div class="discounts__container">
                <h2 class="title">{{ title }}</h2>
                <div class="dishes">
                    <div
                        class="dish"
                        v-for="discount in discounts"
                        :key="discount.id"
                        :id="discount.id"
                        @click="discount.id === 1 ? $emit('popupLogin') : routerHrefLink()"
                    >
                        <div class="dish__view">
                            <div class="dish__img">
                                <img :src="require(`@/assets/img/discounts/${discount.img}`)" :alt="discount.title" />
                            </div>
                        </div>
                        <div class="dish__body">
                            <h4 class="dish__title">{{ discount.title }}</h4>
                            <div class="dish__flex">
                                <div class="dish__text">
                                    {{ discount.text }}
                                </div>
                                <div class="dish__bottom">
                                    <button
                                        class="dish__more"
                                        @click="discount.id === 1 ? $emit('popupLogin') : routerHrefLink()"
                                    >
                                        {{ discount.button }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <popup-mask-disc
        :class="popupMore ? '_show' : ''"
        @close="popupMore = false"
        v-for="discount in discounts"
        :key="discount.id"
        :popupId="popupId"
        :discount="discount"
    />
</template>

<script>
import PopupMaskDisc from "@/components/popups/PopupMaskDisc.vue";
export default {
    name: "DiscountsMain",
    components: { PopupMaskDisc },
    props: ["token"],
    data() {
        return {
            title: "Акции и новости",
            popupMore: false,
            popupId: 0,
            discounts: [
                {
                    id: 1,
                    title: `Кешбэк 5% с каждого заказа`,
                    text: `Участвуйте в программе лояльности\n сети ресторанов TATAR`,
                    img: "percent.png",
                    button: "Зарегистрироваться",
                },
                {
                    id: 2,
                    title: "Дарим 100 бонусов",
                    text: `Дарим 100 приветственных бонусов,\n чтобы попробовать блюда\n ресторана`,
                    img: "telegram.png",
                    button: "Подписаться",
                },
            ],
            telegram: "https://t.me/Tatar_tbtbot?start=link_EuH0QI6KnX",
        };
    },
    methods: {
        showPopupId(popupId) {
            console.log(popupId);
            this.popupId = popupId;
            this.popupMore = true;
        },
        routerHrefLink() {
            window.open(this.telegram, "_blank");
        },
    },
};
</script>

<style lang="scss">
.discounts {
    background-color: #fafafa;
    padding: 50px 0;
    .dishes {
        display: flex;
        justify-content: center;
        @media (max-width: $ms3) {
            flex-direction: column;
        }
    }
    .dish {
        margin-top: 35px;
        max-width: 432px;
        height: auto;
        @media (max-width: $ms3) {
            margin-top: 20px;
            max-width: unset;
            &__title {
                font-size: 18px;
            }
        }
        &__title {
            white-space: pre-line;
        }
        &__flex {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }
        &__img {
            height: 254.77px;
            @media (max-width: $ms3) {
                height: 184px;
            }
        }

        &__text {
            margin-bottom: 0;
            margin-top: 10px;
            white-space: pre-line;
            @media (max-width: $ms3) {
                font-size: 12px;
            }
        }

        &__bottom {
            display: flex;
            margin-top: 10px;
        }
        &__more {
            font-family: "Montserrat", sans-serif;
            width: 100%;
            height: 72px;
            border-radius: 8px;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #2a374b;
            text-align: center;
            @media (max-width: $ms4) {
                font-size: 8px;
                line-height: 14px;
                text-decoration: underline;
            }
        }
        &__body {
            padding: 15px 20px 20px;
            @media (max-width: $ms4) {
                padding: 10px 10px 20px;
            }
        }
    }
}
.popup {
    &__title {
        white-space: pre-line;
    }
    &__img {
        height: 300px;
    }
    &__text {
        a {
            color: #000;
            text-decoration: underline;
        }
    }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/views/MainPage.vue";
//import MainPage from "@/views/MainPageTest.vue";

const routes = [
    {
        path: "/",
        name: "main",
        component: MainPage,
    },
    {
        path: "/reservation",
        name: "reservation",
        component: () => import("@/views/ReservationTable.vue"),
    },
    {
        path: "/account",
        name: "account",
        component: () => import("@/views/AccountPage.vue"),
    },
    {
        path: "/loyalty-program",
        name: "loyalty-program",
        component: () => import("@/views/LoyaltyProgram.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
